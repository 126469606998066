import React, { useState } from "react"
import styled from "@emotion/styled"

const MenuDiv = styled.div`
  transition: transform 0.5s ease;
  position: absolute;
  top: 0px;
  height: 100vh;
  padding-top: 50px;
  width: 100%;
  z-index: -1;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch; // mobile safari
`

const MenuBar = styled.div`
  width: 40px;
  height: 7px;
  background-color: black;
  border: 2px solid white;
  margin: 6px 0;
`

const MenuButton = styled.div`
  flex: 0 0 auto;
  display: block;
  font-size: 2rem;
  font-weight: 800;
  padding: 0 2rem 2rem 1rem;
  cursor: pointer;
`

const MenuBars = () => (
  <>
    <MenuBar></MenuBar>
    <MenuBar></MenuBar>
    <MenuBar></MenuBar>
  </>
)

const MenuCross = () => (
  <>
    <MenuBar style={{ transform: "translateY(7px) rotate(135deg)" }}></MenuBar>

    <MenuBar
      style={{ transform: "translateY(-7px) rotate(-135deg)" }}
    ></MenuBar>
  </>
)

const MobileMenu = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const threebars = showMenu ? <MenuCross /> : <MenuBars />
  const onClick = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true)
  }
  return (
    <>
      <MenuButton onClick={onClick}>{threebars}</MenuButton>
      {showMenu ? (
        <MenuDiv className={`backgroundMobileMenu `}>{children}</MenuDiv>
      ) : null}
    </>
  )
}

export default MobileMenu

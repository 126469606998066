import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import Facebook from "../images/Facebook.inline.svg"
import Mastodon from "../images/Mastodon_Logo.inline.svg"
import { device } from "./styles"
import { StaticImage } from "gatsby-plugin-image"

const FooterContainer = styled.footer`
  grid-area: footer;
  margin: 80px 0 0 0;
  width: 100%;
  padding: 0 0 0 0;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  height: 300px;
  @${device.tablet} {
    height: 25vh;
  }
`

const FlexLinkSec = styled.div`
  max-width: 1400px;
  min-width: 40%;
  display: flex;
  flex-flow: row wrap;
  margin-left: 20px;
  justify-content: space-evenly;
  align-items: center;
  @${device.tablet} {
    min-width: 500px;
  }
`
const LinkSec = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  @${device.tablet} {
  }
`

const FootLink = styled(Link)`
  color: black;
  transition: color 0.2s ease;
  text-decoration: none;
  margin: 0 0 0px 0px;
  padding: 0 0 0 0px;

  &:hover {
    text-decoration: underline;
    color: rgb(255, 110, 180);
  }
`

const KennDeinLimit = styled.a`
  padding: 0;
  width: 180px;
  height: auto;
  margin: 5px 2px 3px 2px;
  margin: 0;
  @${device.tablet} {
    top: -30px;
    width: 270px;
    height: auto;
    margin: 0;
  }
`
/*
const Solides = styled(SolidesFooter)`
  height: 50px;
  width: 180px;
`
*/
const FootTop = styled.div`
  font-family: var(--solifont);
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
`
const SocialMedia = styled.a`
  display: inline-box;
  padding: 8px 16px 8px 0px;
  width: 50px;
  height: 50px;
  z-index: 1;

  /*text-decoration:none;
color:black;
font-size:34px;
align-items:center;
white-space: nowrap;*/
  @${device.tablet} {
    padding: 10px 20px 10px 0px;
    width: 60px;
    height: 60px;
  }
`
/*
const SoliMedia = styled.div`
color:black;  
font-weight: 600;
padding-left:10px;
margin:0;
text-decoration:underline;
text-decoration:none;

`

const Logo = styled(SoliBier)`
  position: relative;
  top: -20px;
  box-shadow: inset 0 0rem 10px 0rem black;
  padding: 0;
  border-radius: 50%;
  background: white;
  width: 120px;
  height: auto;
  border: 2px solid black;
  margin: 5px 2px 3px 2px;
  margin: 0;
  @${device.tablet} {
    top: -50px;
    height: auto;
    width: 150px;
    margin: 0;
  }
  display: none;
`
const LogoLink = () => (
  <FootLink to="/">
    <Logo />
  </FootLink>
)
*/
const Footer = () => (
  <FooterContainer className="footer">
    <FlexLinkSec>
      <LinkSec>
        <div>
          <SocialMedia href="https://mastodon.social/@solides">
            <Mastodon style={{ width: "40px", flex: "1 0 auto" }} />
            {/*<SoliMedia>@SoliBier</SoliMedia>*/}
          </SocialMedia>
          <SocialMedia href="https://de-de.facebook.com/solibiergoe">
            <Facebook style={{ width: "40px", flex: "1 0 auto" }} />
            {/*<SoliMedia>SoliBierGoe</SoliMedia>*/}
          </SocialMedia>
        </div>
        <div>
          <FootLink to="/impressum/#Kontakt">Kontakt</FootLink>
          <br />
          <FootLink to="/impressum/#Impressum">Impressum</FootLink>
          <br />
          <FootLink to="/impressum/#Datenschutz">Datenschutz</FootLink>
        </div>
      </LinkSec>
      <LinkSec>
        <FootTop>SoliBier</FootTop>
        Hagenweg 2a <br />
        37081 Göttingen <br />
        <a href="mailto:info@solibier.de">info@solibier.de</a>
      </LinkSec>
    </FlexLinkSec>
    <KennDeinLimit href="https://www.kenn-dein-limit.info/">
      <StaticImage
        src="../images/kenn-dein-limit2.png"
        alt="Kenn dein Limit"
        placeholder="traced_svg"
      />
    </KennDeinLimit>
  </FooterContainer>
)

export default Footer

import styled from "@emotion/styled"

export const SoliCardBG = "var(--bgcolortrans), var(--greyrepeat)"
const SoliCardBorder = "var(--border)"
export const SoliCardColor = "black"

export const device = {
  //mobile first .. standard ist also Screen schmaler als 480px..
  mobileM: `media only screen and (min-width: 30em)`, //=480px
  mobileL: `media only screen and (min-width: 40em)`, //=620px
  tablet: `media only screen and (min-width: 49em)`, //=800px
  laptop: `media only screen and (min-width: 75em)`, //=1200 alt=1040px
  monitor: `media only screen and (min-width: 100em)`, //=1600; alt=1488px
}

export const SoliBlack = styled.h2`
  font-family: var(--solifont);
  font-size: 2rem;
  color: black;
  font-weight: 500;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  margin: 0;
  text-decoration: underline;
`

export const SoliWhite = styled.h1`
  font-family: var(--solifont);
  font-size: 2rem;
  padding: 0.5rem;
  font-weight: 500;

  color: ${SoliCardColor};
  text-decoration: underline;
`
export const SoliCard = styled.div`
  font-family: var(--solifont);
  font-size: 1.8rem;
  color: ${SoliCardColor};
  font-weight: 500;
  line-height: 1;
  text-rendering: optimizeLegibility;
  padding: 0.5rem;
  text-decoration: none;
  border: ${SoliCardBorder};
  background: ${SoliCardBG};
  background-repeat: norepeat, repeat;
  box-shadow: 0 0rem 0.5rem 0rem 0;
  display: inline-block;
  max-width: 500px;
`

export const ProjektLink = styled.a`
  font-family: var(--solifont);
  font-size: 1.8rem;
  color: ${SoliCardColor};
  font-weight: 500;
  line-height: 1;
  text-rendering: optimizeLegibility;
  padding: 0.5rem;
  margin: 0 0 4px 0px;
  text-decoration: none;
  border: ${SoliCardBorder};
  background: ${SoliCardBG};
  background-repeat: norepeat, repeat;
  box-shadow: 0 0rem 0.5rem 0rem 0;
`

export const TextCard = styled.div`
  margin: 0rem 0 2rem 0;
  background: var(--etigrey);
`
export const TextCardHeader = styled.div`
  width: 100%;
  background: var(--etigrey);

  display: flex;
  justify-content: space-between;
`

export const NewsDate = styled.div`
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px;
  margin: 0;
  font-family: "Alegreya Sans";
  float: right;
`
export const Anchor = styled.div`
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
`
/*
export const ImageFloat = styled.img`
  margin: 0.2rem;
  padding: 0 0rem 0 0rem;
  display: inline-block;
  width: 50%;
  max-width: 120px;
  float: right;
`*/

export const Text = styled.div`
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  background: var(--etigrey);
  margin: 0;
  max-width: 800px;

  font-family: "Alegreya Sans";
`
export const BigText = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  /* background: var(--etigrey);*/

  padding: 0.5rem;
  margin: 0;
  font-family: "Alegreya Sans";
`

export const SoliStyleWhite = styled.div`
  font-family: var(--solifont);
  text-align: center;
  text-shadow: rgb(255, 255, 255) 4px 0px 0px,
    rgb(255, 255, 255) 3.87565px 0.989616px 0px,
    rgb(255, 255, 255) 3.51033px 1.9177px 0px,
    rgb(255, 255, 255) 2.92676px 2.72656px 0px,
    rgb(255, 255, 255) 2.16121px 3.36588px 0px,
    rgb(255, 255, 255) 1.26129px 3.79594px 0px,
    rgb(255, 255, 255) 0.282949px 3.98998px 0px,
    rgb(255, 255, 255)-0.712984px 3.93594px 0px,
    rgb(255, 255, 255)-1.66459px 3.63719px 0px,
    rgb(255, 255, 255)-2.51269px 3.11229px 0px,
    rgb(255, 255, 255)-3.20457px 2.39389px 0px,
    rgb(255, 255, 255)-3.69721px 1.52664px 0px,
    rgb(255, 255, 255)-3.95997px 0.56448px 0px,
    rgb(255, 255, 255)-3.97652px -0.432781px 0px,
    rgb(255, 255, 255)-3.74583px -1.40313px 0px,
    rgb(255, 255, 255)-3.28224px -2.28625px 0px,
    rgb(255, 255, 255)-2.61457px -3.02721px 0px,
    rgb(255, 255, 255)-1.78435px -3.57996px 0px,
    rgb(255, 255, 255)-0.843183px -3.91012px 0px,
    rgb(255, 255, 255) 0.150409px -3.99717px 0px,
    rgb(255, 255, 255) 1.13465px -3.8357px 0px,
    rgb(255, 255, 255) 2.04834px -3.43574px 0px,
    rgb(255, 255, 255) 2.83468px -2.82216px 0px,
    rgb(255, 255, 255) 3.44477px -2.03312px 0px,
    rgb(255, 255, 255) 3.84068px -1.11766px 0px,
    rgb(255, 255, 255) 3.9978px -0.132717px 0px;
  white-space: nowrap;
  font-weight: 600;
  padding: 0.2rem 0.3rem;
  color: var(--solicolor);
  color: black;
  translate: 0 -20px;
`

export const SoliTooWhite = styled.div`
  font-weight: 600;
  padding: 0.2rem 0.3rem;
  color: var(--solicolor);
  font-family: var(--solifont);
  text-align: center;
  font-size: 3.5rem;
  line-height: 1.5;
  z-index: 0;
  text-shadow: rgb(255, 255, 255) 9px 0px 0px,
    rgb(255, 255, 255) 8.9445px 0.997944px 0px,
    rgb(255, 255, 255) 8.77869px 1.98358px 0px,
    rgb(255, 255, 255) 8.50461px 2.94475px 0px,
    rgb(255, 255, 255) 8.12565px 3.86961px 0px,
    rgb(255, 255, 255) 7.64647px 4.74674px 0px,
    rgb(255, 255, 255) 7.07299px 5.56533px 0px,
    rgb(255, 255, 255) 6.41227px 6.31528px 0px,
    rgb(255, 255, 255) 5.67248px 6.98735px 0px,
    rgb(255, 255, 255) 4.86272px 7.57324px 0px,
    rgb(255, 255, 255) 3.99299px 8.06573px 0px,
    rgb(255, 255, 255) 3.07402px 8.45875px 0px,
    rgb(255, 255, 255) 2.11714px 8.74744px 0px,
    rgb(255, 255, 255) 1.13414px 8.92825px 0px,
    rgb(255, 255, 255) 0.137162px 8.99896px 0px,
    rgb(255, 255, 255) -0.861512px 8.95867px 0px,
    rgb(255, 255, 255) -1.84956px 8.8079px 0px,
    rgb(255, 255, 255) -2.8148px 8.5485px 0px,
    rgb(255, 255, 255) -3.74532px 8.18368px 0px,
    rgb(255, 255, 255) -4.62965px 7.71792px 0px,
    rgb(255, 255, 255) -5.45689px 7.15698px 0px,
    rgb(255, 255, 255) -6.21682px 6.50777px 0px,
    rgb(255, 255, 255) -6.90009px 5.77831px 0px,
    rgb(255, 255, 255) -7.49825px 4.97757px 0px,
    rgb(255, 255, 255) -8.00394px 4.11545px 0px,
    rgb(255, 255, 255) -8.41092px 3.20258px 0px,
    rgb(255, 255, 255) -8.71416px 2.2502px 0px,
    rgb(255, 255, 255) -8.90993px 1.27008px 0px,
    rgb(255, 255, 255) -8.99582px 0.274291px 0px,
    rgb(255, 255, 255) -8.97076px -0.72488px 0px,
    rgb(255, 255, 255) -8.83507px -1.71511px 0px,
    rgb(255, 255, 255) -8.59041px -2.68419px 0px,
    rgb(255, 255, 255) -8.23981px -3.62017px 0px,
    rgb(255, 255, 255) -7.78758px -4.51149px 0px,
    rgb(255, 255, 255) -7.23931px -5.34718px 0px,
    rgb(255, 255, 255) -6.60176px -6.11692px 0px,
    rgb(255, 255, 255) -5.88279px -6.81122px 0px,
    rgb(255, 255, 255) -5.09127px -7.42152px 0px,
    rgb(255, 255, 255) -4.23696px -7.94029px 0px,
    rgb(255, 255, 255) -3.33039px -8.36113px 0px,
    rgb(255, 255, 255) -2.38275px -8.67885px 0px,
    rgb(255, 255, 255) -1.40572px -8.88954px 0px,
    rgb(255, 255, 255) -0.411357px -8.99059px 0px,
    rgb(255, 255, 255) 0.58808px -8.98077px 0px,
    rgb(255, 255, 255) 1.58026px -8.86018px 0px,
    rgb(255, 255, 255) 2.55296px -8.63032px 0px,
    rgb(255, 255, 255) 3.49417px -8.29402px 0px,
    rgb(255, 255, 255) 4.39229px -7.85543px 0px,
    rgb(255, 255, 255) 5.23623px -7.31996px 0px,
    rgb(255, 255, 255) 6.0156px -6.69422px 0px,
    rgb(255, 255, 255) 6.72078px -5.98591px 0px,
    rgb(255, 255, 255) 7.34307px -5.20378px 0px,
    rgb(255, 255, 255) 7.8748px -4.35748px 0px,
    rgb(255, 255, 255) 8.3094px -3.45743px 0px,
    rgb(255, 255, 255) 8.64153px -2.51474px 0px,
    rgb(255, 255, 255) 8.86709px -1.54104px 0px,
    rgb(255, 255, 255) 8.98328px -0.548328px 0px;
`
/*
export const SoliLinkCard = styled(Link)`
text-decoration:none;
margin: 1rem 6px 0 0;
color: ${SoliCardColor};
border: ${SoliCardBorder};
background: ${SoliCardBG};
background-repeat:norepeat,repeat;

display:flex;

width:100%;
height:80vh;
width:100vw;
&:visited{color:none;}
}
`
*/
/*
export const BigImageFloat = styled.img`
margin:0.2rem;
padding:0 0rem 0 0rem;
display:inline-block;
height: auto;
max-width: 50%;
float: right;
`
*/

import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { SoliStyleWhite } from "./styles"
import Logo from "../images/solides-logo5.inline.svg"
import BeerClosed from "../images/Beer_closed.inline.svg"
import BeerOpen from "../images/Beer_open.inline.svg"
import BigFlower from "../images/BigFlower.inline.svg"
import SmallFlower from "../images/SmallFlower.inline.svg"
import BigRoots from "../images/BigRoots.inline.svg"
import BirdFly from "../images/BirdFly.inline.svg"
import BirdSing from "../images/BirdSing.inline.svg"
import BirdSit from "../images/BirdSit.inline.svg"
import BirdSitSing from "../images/BirdSitSing.inline.svg"
import Seed from "../images/Seed.inline.svg"
import Sprout from "../images/Sprout.inline.svg"
import { css } from "@emotion/react"
import Mail from "../images/envelope-o.inline.svg"
import MailOpen from "../images/envelope-open-o.inline.svg"

const FloatSvg = css`
  margin: 0.2rem;
  padding: 0 0rem 0 0rem;
  display: inline-block;
  width: 40%;
  max-width: 100px;
  min-width: 80px;
  float: right;
`

const HeaderLink = styled(Link)``

export const SoliMail = styled(Mail)`
margin: 2rem;
padding: 0 0rem 0 0rem;
width: 40%; 
max-width: 100px;
min-width: 80px;
float: right;
`
export const SoliMailOpen = styled(MailOpen)`
  ${FloatSvg}
`
export const BeerFloat = styled(BeerOpen)`
  ${FloatSvg}
`
export const FlowerFloat = styled(BigFlower)`
  ${FloatSvg}
`
export const RootsFloat = styled(BigRoots)`
  ${FloatSvg}
`
export const FlyFloat = styled(BirdFly)`
  ${FloatSvg}
`
export const SitFloat = styled(BirdSit)`
  ${FloatSvg}
`
export const SingFloat = styled(BirdSing)`
  ${FloatSvg}
`
export const SproutFloat = styled(Sprout)`
  ${FloatSvg}
`

export const LogoLink = () => (
  <HeaderLink className="headerlinks logo" to="/">
    <Logo />
  </HeaderLink>
)

export const MailLink = () => (
  <a
    id="mail"
    href="https://mail.solides-bier.de/"
    className="passivelink maillink"
    activeClassName="activelink"
  >
    <div/>
    <SoliMail />
    <SoliMailOpen/>
  </a>
)

export const NewsLink = () => (
  <HeaderLink
    to="/blog/"
    className="passivelink headerlinks transm10"
    activeClassName="activelink"
  >
    <SoliStyleWhite>Blog</SoliStyleWhite>
    <BirdSitSing />
    <BirdSing />
  </HeaderLink>
)

export const IdeeLink = () => (
  <HeaderLink
    to="/idee/"
    className="passivelink headerlinks trans10"
    activeClassName="activelink"
  >
    <SoliStyleWhite>Idee</SoliStyleWhite>
    <Seed />
    <Sprout />
  </HeaderLink>
)

export const ProjekteLink = () => (
  <HeaderLink
    to="/projekte/"
    className="passivelink headerlinks trans30"
    activeClassName="activelink"
  >
    <SoliStyleWhite>SoliProjekte</SoliStyleWhite>
    <SmallFlower /> <BigFlower />
  </HeaderLink>
)

export const BierLink = () => (
  <HeaderLink
    to="/bier/"
    className="passivelink headerlinks trans40"
    activeClassName="activelink"
  >
    <SoliStyleWhite>Unser&nbsp;Bier</SoliStyleWhite>
    <BeerClosed />
    <BeerOpen />
  </HeaderLink>
)

export const VertriebLink = () => (
  <HeaderLink
    to="/vertrieb/"
    className="passivelink headerlinks trans35"
    activeClassName="activelink"
  >
    <SoliStyleWhite>Bier?Hier!</SoliStyleWhite>
    <BirdSit />
    <BirdFly />
  </HeaderLink>
)

export const HeaderLinks = () => (
  <>
    <NewsLink />
    <IdeeLink />
    <ProjekteLink />
    {/* <VereinLink /> */}
    <BierLink />
    <VertriebLink />
  </>
)

import React, { useState } from "react"
import styled from "@emotion/styled"
import MobileMenu from "./mobilemenu"
import { HeaderLinks, LogoLink } from "./headerlinks"
import useDocumentScrollThrottled from "./useDocumentScroll"

const HeaderMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
`
const HeaderScreen = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
`

const FlexLinks = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: stretch;
  justify-content: flex-end;
  max-width: 960px;
`

const HeaderScroll = ({ site, ClassName }) => {
  const [shouldShowHeader, setShouldShowHeader] = useState(false)

  const TIMEOUT_DELAY = 400
  useDocumentScrollThrottled((callbackData) => {
    const { currentScrollTop } = callbackData
    const { clientHeight: windowHeight } = document.documentElement
    setShouldShowHeader(currentScrollTop > windowHeight * 0.6)
    setTimeout(() => {}, TIMEOUT_DELAY)
  })

  const showStyle = shouldShowHeader ? "show" : ""
  const showAllways = site ? "" : "show"
  return (
    <>
      <div className={`placeholder`} />
      <nav className={`header ${showStyle} ${showAllways} ${site}`}>
        <HeaderMobile id="mobileheader">
          <LogoLink />
          <MobileMenu>
            <HeaderLinks />
          </MobileMenu>
        </HeaderMobile>

        <HeaderScreen id="screenheader">
          <LogoLink />
          <FlexLinks>
            <HeaderLinks />
          </FlexLinks>
        </HeaderScreen>
      </nav>
    </>
  )
}

export default HeaderScroll

import React from "react"
import Footer from "./footer"
import styled from "@emotion/styled"
import "./layout.css"
import { device } from "./styles"
import { Helmet } from "react-helmet"
import HeaderScroll from "./header"

const BodyContainer = styled.div`
  background: var(--bubbles);
  background-repeat: repeat;
  background-size: contain;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-gap: 20px 0.5em;
  grid-template-areas:
    "header"
    "right"
    "left"
    "content"
    "footer";
  @${device.tablet} {
    grid-template-columns: 1fr 20fr 1fr;
    grid-template-areas:
      "header header header"
      "left right ."
      "left content ."
      "footer footer footer";
  }
  @${device.laptop} {
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-areas:
      "header header header"
      "left right ."
      "left content ."
      "footer footer footer";
  }
  @${device.monitor} {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas:
      "header header header"
      "left content right"
      "footer footer footer";
  }
`

const MainContainer = styled.main`
  grid-area: content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
`
const AsideLeft = styled.aside`
  grid-area: left;
  padding: 5px;

  @${device.laptop} {
    padding: 0 0 0 10px;
  }
  @${device.monitor} {
    padding: 0 0 0 10px;
  }
`
const AsideRight = styled.aside`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-area: right;
  padding: 5px;

  @${device.laptop} {
  }
  @${device.monitor} {
    padding: 0 10px 0 0;
  }
`
const TopContent = styled.div`
  grid-area: header;
`

const Layout = ({ children, leftside, rightside, topcontent, site }) => (
  <>
    <Helmet htmlAttributes={{ lang: "de" }}>
      <title>Solides</title>
      <meta
        name="google-site-verification"
        content="-PZOnDaxRd5nFICxN9-oDqIjgSiIV8tp0n_URIx6Xto"
      />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=10, minimum-scale=0.5, maximum-scale=5.0"
      />
      <meta
        name="description"
        content="Solides - regional, solidarisch, lecker. Genießen und Spenden für den Guten Zweck in Göttingen. Gebraut bei Schinkels in Witzenhausen"
      />
    </Helmet>
    <BodyContainer>
      <HeaderScroll site={site} />
      <TopContent>{topcontent}</TopContent>
      <AsideLeft>{leftside}</AsideLeft>
      <MainContainer>{children}</MainContainer>
      <AsideRight>{rightside}</AsideRight>
      <Footer />
    </BodyContainer>
  </>
)

export default Layout

import React from "react"
import { Link } from 'gatsby'
import { domToReact } from 'html-react-parser';

export const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) return;
   
      if (attribs.to) {
        return <Link to={attribs.to}>
          {domToReact(children, options)}
          </Link>;
      }

   
    }
  };